<div class="content-heading">Orders
 <small><a [routerLink]=" ['/home'] ">Dashboard</a> ->
        <a [routerLink]="['/order/allOrder']"> Orders </a>->
            Order Detail
 </small>
</div>
<div class="panel panel-default" *ngIf="orderDetails != null">
    <div class="panel-heading">Order Information ({{orderDetails.suborderId}})</div>
    <div class="panel-heading"><b>Seller: {{orderDetails.sellerInfo.storeName}}</b> Tel: {{orderDetails.sellerInfo.phoneNo}}</div>
    <div class="panel-body">
        <div class="row">
            <div class="col-md-6">
                <p class="lead bb">Details</p>
                <form class="form-horizontal p-20">
                    <div class="form-group">
                        <div class="col-sm-4">Purchased On:</div>
                        <div class="col-sm-8">
                            <strong>{{orderDetails.createdAt|date:'medium'}}</strong>
                        </div>
                    </div>                   
                    <div class="form-group">
                        <div class="col-sm-4">Items:</div>
                        <div class="col-sm-8">
                            <strong>{{orderDetails.items.length}}</strong>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-4">Estimated Delivery:</div>
                        <div class="col-sm-8">
                            <strong>{{orderDetails.deliverydetails.etautc|date:'medium'}}</strong>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-4">Payment Mode:</div>
                        <div class="col-sm-8">
                            <strong>{{orderDetails.paymentMethod}}</strong>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-4">Razor Pay Payment Id:</div>
                        <div class="col-sm-8">
                            <strong>{{orderDetails.razorpay_paymentid}}</strong>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-4">Amount Paid to Seller (via RazorPay on delivery):</div>
                        <div class="col-sm-8">
                            <strong>{{orderDetails.sellersettlement.amountpaid}}</strong>
                        </div>
                    </div>                    
                    <div class="form-group">
                        <div class="col-sm-4">Seller gets:</div>
                        <div class="col-sm-8">
                            <strong>{{orderDetails.totalitemprice}} - {{orderDetails.vegeciouscommissionamt}}({{orderDetails.vegeciouscommission}}%) + {{orderDetails.sellerdeliverypasson}}={{orderDetails.itemtotaltoseller}}</strong>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-4">Amount left to be paid to Seller:</div>
                        <div class="col-sm-8">
                            <strong>{{orderDetails.amountlefttoseller}}</strong>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <p class="lead bb">Customer</p>
                <form class="form-horizontal p-20">
                    <div class="form-group">
                        <div class="col-sm-4">Name:</div>
                        <div class="col-sm-8">
                            <strong>{{userDetails.name}}</strong>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-4">Email:</div>
                        <div class="col-sm-8">
                            <strong>{{userDetails.email}}</strong>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-4">Phone:</div>
                        <div class="col-sm-8">
                            <strong>{{userDetails.mobileNo}}</strong>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <p class="lead bb">Delivery Address</p>
                <form class="form-horizontal p-20">
                     <div class="form-group">
                        <div class="col-sm-4">Full Name</div>
                        <div class="col-sm-8">
                            <strong>{{userDetails.name}}</strong>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-4">Address:</div>
                        <div class="col-sm-8">
                            <strong>{{address.address}}</strong>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-4">City:</div>
                        <div class="col-sm-8">
                            <strong>{{address.city}}</strong>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-4">ZIP/Post Code</div>
                        <div class="col-sm-8">
                            <strong>{{address.zipcode}}</strong>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-4">Phone</div>
                        <div class="col-sm-8">
                            <strong>{{address.phoneno}}</strong>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <p class="lead bb">Delivery Instructions</p>
                <strong>{{orderDetails.deliverydetails.instructions}}</strong>
            </div>
        </div>
        <div class="panel-heading">Products in order</div>
        <div class="table-responsive">
            <table class="table table-hover table-bordered table-striped">
                <thead>
                    <tr>
                        <th class="text-center">#</th>
                        <th class="text-center">Item Name</th>
                        <th class="text-center">Price</th>
                        <th class="text-center">Quantity</th>   
                        <th class="text-center">Item Total</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container  *ngFor="let data of orderDetails.items| paginate: { itemsPerPage: 5, currentPage: p }; let i = index ">
                        <tr [class.row-border-bottom]="data.specialinstr != null && data.specialinstr.length==0">
                            <td class="text-center">{{i+1}}.</td>
                            <td class="text-left">
                                <div style="font-size: medium;">{{data.title}}</div>
                                <div style="font-size: small;" *ngIf="(data.extraOptions != null) && (data.extraOptions.length>0)">
                                    <div *ngFor="let addon of data.extraOptions; let j=index">
                                        <p style="margin-left: 10px;">{{j+1}}. {{addon.name}} ({{addon.quantity}})</p>
                                    </div>
                                </div>                            
                            </td>
                            <td class="text-center">
                                {{data.price.value}}
                            </td>
                            <td class="text-center">{{data.itemQuantity}}</td>
                            <td class="text-right">{{data.itemTotalPrice}}</td>
                        </tr>
                        <tr *ngIf="data.specialinstr != null && data.specialinstr.length>0" class="row-border-bottom">
                            <td></td>
                            <td class="text-left" colspan="4" style="font-style: italic;padding-left: 20px;font-size: small;">{{data.specialinstr}}</td>
                        </tr>
                    </ng-container>
                    <tr class="row-border-bottom">
                        <td colspan="4" style="border-top: 2px dashed black;">Delivery Charge (By {{orderDetails.deliverybyname}})</td>
                        <td class="text-right" style="border-top: 2px dashed black;">{{orderDetails.deliverycharge}}</td>
                    </tr>
                    <tr class="row-border-bottom">
                        <td colspan="4">Tax ({{orderDetails.taxpct}} %)</td>
                        <td class="text-right">{{orderDetails.taxamt}}</td>
                    </tr>
                    <tr class="row-border-bottom total">
                        <td colspan="4">Total (to Seller)</td>
                        <td class="text-right">{{orderDetails.itemtotaltoseller}}</td>
                    </tr>
                    <tr class="row-border-bottom total">
                        <td colspan="4">Discount ({{orderDetails.promocode}})</td>
                        <td class="text-right">- {{orderDetails.promodiscamt}}</td>
                    </tr>
                    <tr class="row-border-bottom total">
                        <td colspan="4">Total Amount charged (to Buyer)</td>
                        <td class="text-right">{{orderDetails.grandTotal}}</td>
                    </tr>
                </tbody>    
            </table>
        </div>
        <div style="margin-top: 15px;" *ngIf="isAdmin">
            <div style="font-weight: bold;">RazorPay Transactions</div>
            <div *ngFor="let tx of orderTransactions" style="border-bottom: 1px solid black;">
                <span>{{tx.utctime}}</span>
                <span style="margin-left: 10px;">{{tx.msg}}</span>
            </div>
        </div>
    </div>
    <div class="panel-footer" style="height: 100px;">
        <div class="row ">
            <div class="pull-right pagination-sm" >
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
            <div class="form-group">
                <div class="col-sm-4">Status</div>
                <div class="col-sm-8">
                    <select class="input-sm form-control" [(ngModel)]="orderDetails.status" (change)="OnChangeStatus(modalWithDatepicker, modalWithDatepickerForCOD, $event)">
                        <option>Select Status</option>
                        <option value="Pending">1. Pending</option>
                        <option value="Accepted">2. Accepted</option>
                        <option value="On the Way">3. On the Way</option>
                        <option value="Canceled">4. Canceled</option>
                        <option value="Delivered">5. Delivered</option>
                    </select>
                    <div *ngIf="orderDetails.status == 'Canceled'" style="margin-top: 10px;margin-left: 5px;font-weight: bold;">
                        Cancel Reason: {{orderDetails.cancelreason}}
                    </div>                    
                    <!--<span class="label label-success" *ngIf="orderDetails.status=='Delivered'">{{orderDetails.status}}</span>
                    <span class="label label-success" *ngIf="orderDetails.status=='Accepted'">{{orderDetails.status}}</span>
                    <span class="label label-warning" *ngIf="orderDetails.status =='Pending'">{{orderDetails.status}}</span>
                    <span class="label label-purple" *ngIf="orderDetails.status=='On the Way'">{{orderDetails.status}}</span>
                    <span class="label label-danger" *ngIf="orderDetails.status=='Canceled'">{{orderDetails.status}}</span>
                    -->
                </div>
            </div>
        </div>
     </div>
</div>

<ng-template #modalWithDatepickerForCOD>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Cash on delivery and Estimated Delivery Time</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="test-box">
        <h4>Specify estimated delivery time.</h4>
        <p>Please provide the date and time by which the order will be delivered to the Customer.</p>
        <div class="row">
            <div class="col-xs-12 col-12 col-md-4 form-group">
                <input type="text"
                    placeholder="Datepicker"
                    class="form-control"
                    #dp="bsDatepicker"
                    bsDatepicker
                    [(ngModel)]="etadate"
                    [bsConfig]="{ adaptivePosition: true }">
            </div>
            <div class="col-xs-12 col-12 col-md-4 form-group">
                <timepicker [(ngModel)]="etatime"></timepicker>
            </div>
        </div>
      </div>
      <div class="test-box">
        <h4>Cash On Delivery Order</h4>
        <p>This is a cash on delivery order. Seller has to take the responsibility to contact the buyer and collect the payment on delivery from buyer. Vegecious will not be handling the payment for this transaction either with buyer or with seller..</p>
        <div class="row">
            <div class="col-xs-12 col-12 col-md-4 form-group">
                <input class="form-check-input" type="checkbox" value="" id="acceptCOD" (click)="codtermschanged($event)">
                <label class="form-check-label" for="acceptCOD" style="display: inline;">
                    I acknowledge that this is cash on delivery order and I will be responsible for collecting the payment from buyer at delivery time.
                </label>
            </div>
        </div> 
      </div>
      <div class="modal-body text-center">
        <button type="button" class="btn btn-default" (click)="confirm()" [disabled]="CODConfirmBtnDisabled">Confirm</button>
        <button type="button" class="btn" (click)="decline()" >Cancel</button>
    </div>
    </div>
</ng-template>

<ng-template #modalWithDatepicker>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Delivery ETA</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="test-box">
        <h4>Specify Delivery ETA</h4>
        <p>Please provide the date and time by which the order will be delivered to the Customer.</p>
        <div class="row">
            <div class="col-xs-12 col-12 col-md-4 form-group">
                <input type="text"
                    placeholder="Datepicker"
                    class="form-control"
                    #dp="bsDatepicker"
                    bsDatepicker
                    [(ngModel)]="etadate"
                    [bsConfig]="{ adaptivePosition: true, containerClass: 'theme-blue', showWeekNumbers: false }">
            </div>
            <div class="col-xs-12 col-12 col-md-4 form-group">
                <timepicker [(ngModel)]="etatime"></timepicker>
            </div>
        </div>
        <div>Note: This will cause a payment capture. This will incur Vegecious Transaction fees even if order is cancelled.</div>
        <div class="modal-body text-center">
            <button type="button" class="btn btn-default" (click)="confirm()" >Confirm</button>
            <button type="button" class="btn" (click)="decline()" >Cancel</button>
        </div>
      </div>
    </div>
  </ng-template>

